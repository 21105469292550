/**
 * Array of routes accessible to the public
 * @type {string[]}
 */
export const publicRoutes = ["/signed-out", "/monitoring", "/api", "/auth/new-verification", "/212"]

/**
 * Array of routes used for authentication
 * @type {string[]}
 */
export const authRoutes = ["/sign-in", "/sign-up", "/error", "/auth/reset", "/auth/new-password"]

/**
 * The prefix for API authentication routes
 * Routes that start with this prefix are used for API authentication purposes
 * @type {string}
 */
export const apiAuthPrefix = "/api/auth"

export const DEFAULT_LOGIN_REDIRECT = "/"
