"use client"

import { useState, useEffect } from "react"
import { FcGoogle } from "react-icons/fc"
import { TriangleAlert } from "lucide-react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import type * as z from "zod"
import Link from "next/link"
import { useTransition } from "react"
import { useSearchParams } from "next/navigation"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { LoginSchema } from "@/schemas/auth"

import { login } from "../actions"
import { signIn } from "next-auth/react"
import { useRouter } from "next/navigation"
import { DEFAULT_LOGIN_REDIRECT } from "@/routes"

type FormValues = z.infer<typeof LoginSchema>

const errorMessages: Record<string, string> = {
  OAuthSignin: "Error constructing authorization URL.",
  OAuthCallback: "Error handling the response from the OAuth provider.",
  OAuthCreateAccount: "Error creating OAuth provider user in the database.",
  EmailCreateAccount: "Error creating email provider user in the database.",
  Callback: "Error in the OAuth callback handler route",
  OAuthAccountNotLinked:
    "This email is already associated with another account. Please sign in with the original provider.",
  EmailSignin: "The email verification failed. Please try again.",
  CredentialsSignin:
    "Invalid email or password. Due to recent security updates, you may need to " +
    "reset your password if you're unable to sign in. " +
    'Click "Forgot password?" below to reset it.',
  SessionRequired: "Please sign in to access this page.",
  Default: "An error occurred during authentication.",
}

export const SignInCard = () => {
  const searchParams = useSearchParams()
  const router = useRouter()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isPending, startTransition] = useTransition()

  const form = useForm<FormValues>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  useEffect(() => {
    const errorParam = searchParams?.get("error")
    if (errorParam) {
      setError(errorMessages[errorParam] || errorMessages.Default)
    }
  }, [searchParams])

  const onSubmit = async (values: FormValues) => {
    setError("")
    setSuccess("")

    startTransition(() => {
      login(values)
        .then((data) => {
          if (data?.error) {
            setError(data.error)
          }

          if (data?.success) {
            form.reset()
            setSuccess(data.success)
          }
        })
        .catch((error) => {
          setError(error.message)
        })
    })
  }

  const onProviderSignIn = (provider: "google") => {
    startTransition(() => {
      signIn(provider, { callbackUrl: DEFAULT_LOGIN_REDIRECT })
    })
  }

  return (
    <Card className="p-4 space-y-6 w-full max-w-md sm:p-8">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold sm:text-3xl">Login to continue</h1>
        <p className="text-sm sm:text-base text-muted-foreground">Use your email or another service to continue</p>
      </div>

      {!!error && (
        <div className="flex gap-x-2 items-center p-2.5 sm:p-3 text-sm rounded-md bg-destructive/15 text-destructive">
          <TriangleAlert className="flex-shrink-0 size-4" />
          <p>{error}</p>
        </div>
      )}
      {!!success && (
        <div className="flex gap-x-2 items-center p-2.5 sm:p-3 text-sm text-green-700 rounded-md bg-green-500/15">
          <TriangleAlert className="flex-shrink-0 size-4" />
          <p>{success}</p>
        </div>
      )}

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} disabled={isPending} placeholder="Email" type="email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} disabled={isPending} placeholder="Password" type="password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="mt-2 w-full" size="lg" disabled={isPending}>
            Continue
          </Button>
        </form>
      </Form>

      <div className="flex justify-end">
        <Link href="/auth/reset" className="text-sm text-muted-foreground hover:text-primary hover:underline">
          Forgot password?
        </Link>
      </div>

      <div className="relative my-6">
        <div className="flex absolute inset-0 items-center">
          <div className="w-full border-t" />
        </div>
        <div className="flex relative justify-center text-xs uppercase">
          <span className="px-3 bg-background text-muted-foreground">or continue with</span>
        </div>
      </div>

      <Button
        disabled={isPending}
        onClick={() => onProviderSignIn("google")}
        variant="outline"
        size="lg"
        className="w-full h-11"
      >
        <FcGoogle className="mr-2 size-5" />
        <span className="text-sm">Continue with Google</span>
      </Button>

      <div className="pt-2 text-sm text-center text-muted-foreground">
        Don&apos;t have an account?{" "}
        <Link href="/sign-up" className="font-medium underline underline-offset-4 hover:text-primary">
          Sign up
        </Link>
      </div>
    </Card>
  )
}
